import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-db-ht',
  templateUrl: './db-ht.component.html',
  styles: []
})
export class DBHTComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
