import{NgModule}from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
@NgModule({
    imports:[
        MatButtonModule,
        MatMenuModule,
        MatCardModule,
        MatTreeModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatSelectModule
    ],
    exports:[
        MatButtonModule,
        MatMenuModule,
        MatCardModule,
        MatTreeModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatSelectModule
    ]
})
export class MaterialModule{}