import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes }from '@angular/router';
import { MaterialModule } from './material.module';

//Rutas
const app_routes : Routes=[
  {
    path:"home",component:HomeComponent,
    children:[
      {path:"who-we-are",component:WhoWeAreComponent},
      {path:"mision",component:MisionComponent},
      {path:"vision",component:VisionComponent},
      {path:"values",component:ValuesComponent},
      {path:"**",pathMatch:'full', redirectTo:'who-we-are'}
    ]
  },
  {path:'services',component:ServicesComponent,
    children:[
      {path:"industrial-solutions",component:IndustrialSolutionsComponent},
      {path:"information-systems",component:InformationSystemsComponent},
      {path:"trainings",component:TrainingsComponent},
      {path:"**",pathMatch:'full', redirectTo:'industrial-solutions'}
    ]
  },
  {path:'portal',component:PortalComponent,
    children:[
      {path:"news",component:NewsComponent},
      {path:"our-work",component:OurWorkComponent},
      {path:"**",pathMatch:'full', redirectTo:'our-work'}
    ]
  },
  {path:'contact',component:ContactComponent},
  {path:'**',pathMatch:'full', redirectTo:'home'}
];

//Componentes
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { MisionComponent } from './components/home/mision/mision.component';
import { VisionComponent } from './components/home/vision/vision.component';
import { WhoWeAreComponent } from './components/home/who-we-are/who-we-are.component';
import { ServicesComponent } from './components/services/services.component';
import { ValuesComponent } from './components/home/values/values.component';
import { ProcessAutomationComponent } from './components/services/industrial-solutions/process-automation/process-automation.component';
import { ProcessInstrumentationComponent } from './components/services/industrial-solutions/process-instrumentation/process-instrumentation.component';
import { IndustrialSolutionsComponent } from './components/services/industrial-solutions/industrial-solutions.component';
import { TechnicalServicesComponent } from './components/services/industrial-solutions/technical-services/technical-services.component';
import { InformationSystemsComponent } from './components/services/information-systems/information-systems.component';
import { PortalComponent } from './components/portal/portal.component';
import { OurWorkComponent } from './components/portal/our-work/our-work.component';
import { NewsComponent } from './components/portal/news/news.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/recursion/footer/footer.component';
import { HeaderComponent } from './components/recursion/header/header.component';
import { ControlProjectsComponent } from './components/services/industrial-solutions/control-projects/control-projects.component';
import { TrainingsComponent } from './components/services/trainings/trainings.component';
import { HomeSlideshowComponent } from './components/recursion/home-slideshow/home-slideshow.component';
import { ProductionReportsComponent } from './components/services/information-systems/production-reports/production-reports.component';
import { DBHTComponent } from './components/services/information-systems/db-ht/db-ht.component';
import { SCADAComponent } from './components/services/information-systems/scada/scada.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MisionComponent,
    VisionComponent,
    WhoWeAreComponent,
    ServicesComponent,
    ValuesComponent,
    ProcessAutomationComponent,
    ProcessInstrumentationComponent,
    IndustrialSolutionsComponent,
    TechnicalServicesComponent,
    InformationSystemsComponent,
    PortalComponent,
    OurWorkComponent,
    NewsComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    ControlProjectsComponent,
    TrainingsComponent,
    HomeSlideshowComponent,
    ProductionReportsComponent,
    DBHTComponent,
    SCADAComponent,
  ],
  imports: [
    BrowserModule, 
    RouterModule.forRoot(
      app_routes,
      { /*useHash: true*/ } // <-- debugging purposes only
    ), MaterialModule, 
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}



