import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scada',
  templateUrl: './scada.component.html',
  styles: []
})
export class SCADAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
