import { Component, OnInit, Inject, HostListener,OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  constructor(@Inject(DOCUMENT) document) { }

  ngOnInit() {
  }
  @HostListener('window:scroll', ['$event'])
    onWindowScroll(){
      let e1 = document.getElementById('navbar');
      let e2 = document.getElementById('plx-logo-header');
      let e3 =document.getElementById('logob');
      let e4 =document.getElementById('plx-menu-id');
      if (window.pageYOffset > 10) {
        e1.classList.add('sticky');
        e2.classList.add('resize-logo-text');
        e3.classList.add('resize-logo');
        e3.classList.remove('logob-init');
        e4.classList.add('plx-menu-sticked');
      } else {
        e1.classList.remove('sticky');        
        e2.classList.remove('resize-logo-text');        
        e3.classList.remove('resize-logo'); 
        e3.classList.add('logob-init');       
        e4.classList.remove('plx-menu-sticked');
      }
  }
}
